<template>
  <v-col class="mt-2" >
    <v-col class="shades white pa-6">
      <v-row  class="px-4">
        <v-col cols="12" md="12">
          <h3>등록하실 광고의 종류는 무엇인가요?</h3>
        </v-col>
        <v-col md="5">
          <div class="label mt-6 mb-2">우선 범위를 좁혀볼까요?</div>
          <v-select v-model="form.category.firstName"
                    :items="Object.entries(option.category || {}).map(([k, v]) => ({text: k, value: k})) || []"
                    item-text="text"
                    item-value="value"
                    height="40px"
                    :hide-details="true"
                    outlined solo flat
                    @change="changeScope"></v-select>
        </v-col>
      </v-row>
      <v-col class="px-4">
<!--        <span v-if="form.category.firstName === '매장'" class="label caption" :class="companies.length > 0 ? '' : 'strikethrough'">예상 광고 비용은 월 15-30만원입니다.</span>-->
<!--        <span v-if="form.category.firstName === '제품'" class="label caption" :class="companies.length > 0 ? '' : 'strikethrough'">예상 광고 비용은 월 30-50만원입니다.</span>-->
<!--        <br>-->
        <!-- <span v-if="companies.length == 0 && form.category.firstName === '매장'" class="caption secondary--text">매장 사장님은 2022년까지 슈퍼멤버스가 무료!</span> -->
        <!-- <span v-else-if="companies.length == 0 && form.category.firstName === '제품'" class="caption secondary--text">한정기간 무료 행사중, 광고 개시 후 첫 1개월은 무료입니다.</span> -->
      </v-col>
    </v-col>
    <v-col class="shades white pa-6 mt-2" >
      <v-row class="px-4 mb-4">
        <v-col md="8">
          <div class="label mt-6 mb-3">체험단 종류를 선택해주세요.<span class="caption ml-1 rouge--text">*필수</span></div>
          <v-btn-toggle v-model="form.type" mandatory color="transparent" style="display : flex;"> 
            <v-btn value="S" class="no-hover-effect" :style="{
              backgroundColor : 'transparent',
              border : 'none',
              padding:'0px',
              height : '180px',
              margin : '0px 12px 0px 0px',
            }" :ripple="false">
              <img v-if="form.type === 'S'" :src='require($vuetify.breakpoint.mdAndUp ? "@/assets/img/members-type/standard_active.png" : "@/assets/img/members-type/standard_active_mobile.png")' />
              <img v-else :src='require($vuetify.breakpoint.mdAndUp ? "@/assets/img/members-type/standard_inactive.png" : "@/assets/img/members-type/standard_inactive_mobile.png")' />
            </v-btn>
            <v-btn value="A" class="no-hover-effect" :style="{
              backgroundColor : 'transparent',
              border : 'none',
              padding:'0px',
              height : '180px',
              margin : '0px',
            }" :ripple="false" :disabled="!((premiumPackages?.[0]?.balance || 0))">
              <img v-if="!(premiumPackages?.[0]?.balance || 0)" :src='require($vuetify.breakpoint.mdAndUp ? "@/assets/img/members-type/premium_disabled.png" : "@/assets/img/members-type/premium_disabled_mobile.png")' />
              <img v-else-if="form.type === 'A'" :src='require($vuetify.breakpoint.mdAndUp ? "@/assets/img/members-type/premium_active.png" : "@/assets/img/members-type/premium_active_mobile.png")' />
              <img v-else :src='require($vuetify.breakpoint.mdAndUp ? "@/assets/img/members-type/premium_inactive.png" : "@/assets/img/members-type/premium_inactive_mobile.png")' />
            </v-btn>
          </v-btn-toggle>
          <!-- <v-radio-group v-model="form.type" :mandatory="true" class="radio_group" @change="checkDone" hide-details>
            <v-row class="mb-2">
              <v-radio
                  label="일반 체험단" color="rouge" value="S" hide-details
                  class="radio pl-2 pt-0 mr-2 mt-1 mb-0"></v-radio>
              <v-radio
                  label="프리미엄 체험단" color="rouge" value="A" hide-details
                  class="radio pl-2 pt-0 mr-2 mt-1 mb-0"></v-radio>
            </v-row>
          </v-radio-group> -->
        </v-col>
      </v-row>
      <v-row  class="px-4">
        <v-col cols="12" md="12">
          <v-row>
            <v-col md="5">
              <div class="label mb-2">어떤 분야인가요?</div>
              <v-select v-model="form.category.secondName"
                        :items="Object.entries((option.category[form.category.firstName] || {}).sub || {}).map(([k, v]) => ({text: k, value: k})) || []"
                        item-text="text"
                        item-value="value"
                        height="40px"
                        :hide-details="true"
                        outlined solo flat
                        @change="changeField"></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="12" class="mt-4">
          <v-row>
            <v-col md="5">
              <div class="label mb-2">세부 유형을 선택해주세요.</div>
              <v-select v-model="form.category.thirdName"
                        :items="Object.entries((((option.category[form.category.firstName] || {}).sub || {})[form.category.secondName] || {}).sub || {}).map(([k, v]) => ({value: k, text: k})) || []"
                        item-text="text"
                        item-value="value"
                        height="40px"
                        :hide-details="true"
                        outlined solo flat
                        @change="changeDetail"></v-select>
            </v-col>
          </v-row>
        </v-col>
<!--        <v-col mt-2>-->
<!--          <v-row>-->
<!--            <v-col cols="12" md="5" class="estimate">-->
<!--              <bulbIcon class="light_bulb"/>-->
<!--              &lt;!&ndash;<img src="@/assets/img/regads/light-bulb.svg" class="light_bulb">&ndash;&gt;-->
<!--              <div v-if="form.category.firstName === '매장'">-->
<!--                예상 광고 비용은 <br>-->
<!--                월 15-30만원 입니다.-->
<!--              </div>-->
<!--              <div v-if="form.category.firstName === '제품'">-->
<!--                예상 광고 비용은 <br>-->
<!--                월 30-50만원 입니다.-->
<!--              </div>-->
<!--            </v-col>-->
<!--          </v-row>-->
<!--        </v-col>-->
      </v-row>
      <v-row class="px-4" v-if="form.category.firstName == '매장'">
        <v-col>
          <div class="label mt-6 mb-2">네이버 지도에서 내 가게 정보 불러오기</div>
          <v-btn class="mx-0" color="rouge"
                 outlined tile flat @click="showNaverMapInfo=true"
                 :disabled="!form.category.thirdName">
            불러오기
          </v-btn>
          <div v-if="form.category.thirdName" style="font-size:12px;color: #484848;">네이버 지도에 등록된 업체 정보를 불러올 수 있습니다.</div>
          <div v-else style="font-size:12px;color: #4770BD;">매장의 분야와 세부 유형을 먼저 선택해주세요.</div>
        </v-col>
      </v-row>
    </v-col>
    <v-row justify="center">
      <v-dialog v-model="showNaverMapInfo" content-class="white-two" persistent max-width="400px">
        <v-card class="pa-4">
          <v-card-title>
            <div>
              <v-icon color="default" @click="showNaverMapInfo=false">close</v-icon><br>
              <h2 class="mt-2 font-weight-bold subheading">네이버 지도에서 내 가게 정보 불러오기</h2>
            </div>
          </v-card-title>
          <v-card-text class="pt-0" v-if="naverMapInfoStep === 0">
            <v-row>
              1. 네이버 지도에서 내 가게의 정보 페이지로 이동합니다.<br>
              2. 공유 버튼을 눌러 URL을 복사 후 아래에 붙여넣어주세요.
            </v-row>
            <v-row class="my-2">
              <a @click="showNaverMapInfo=false;showGuide=true" style="text-decoration: underline">URL을 찾기 어려우신가요?</a>
            </v-row>
            <v-row class="mt-6">
              <v-text-field v-model="form.naverPlaceUrl" name="naverPlaceUrl" type="text"
                            :rules="[
                              v => (v || '' ).indexOf('naver.me/') > -1 || '유효한 URL이 아닙니다.',
                              v => (v || '' ).indexOf('http') > -1 || '유효한 URL이 아닙니다.',
                          ]"
                            outlined solo flat error-messages></v-text-field>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0" v-if="naverMapInfoStep === 1">
            <v-row class="mt-2" justify="center" align="center">
              <div>
                <v-progress-circular indeterminate :size="30" :width="5" color="red accent-3"></v-progress-circular>
              </div>
              <div>
                내 가게 정보를 가져오고 있습니다.
              </div>
              <div>
                잠시만 기다려주세요.
              </div>
            </v-row>
          </v-card-text>
          <v-card-text class="pt-0" v-if="naverMapInfoStep === 2">
            <v-row class="mt-2">
              <div>
                불러온 정보가 정확한지 꼭 확인해주세요.
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="naverMapInfoStep === 2" class="rouge white-two--text" flat @click="showNaverMapInfo=false;getNaverMapClose()">닫기</v-btn>
            <v-btn v-if="naverMapInfoStep === 0" color="rouge" outlined flat @click.native="showNaverMapInfo=false;">취소</v-btn>
            <v-btn v-if="naverMapInfoStep === 0" class="rouge white-two--text" flat @click="getNaverMapInfo()">불러오기</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <NaverPlaceGuide v-model="showGuide"></NaverPlaceGuide>
    </v-row>
  </v-col>
</template>
<script>
  import companyMixin from '@/components/shared/companyMixin';
  import {EventBus} from "@/components/shared/event-bus";
  import NaverPlaceGuide from '@/components/campaign/dialog/NaverPlaceGuide';
  import userMixin from '@/components/shared/userMixin';

  export default {
    name: 'Page1',
    mixins: [userMixin, companyMixin],
    props: ['adsData', 'option'],
    components: {
      NaverPlaceGuide
    },
    data: function () {
      return {
        form: {
          category: {
            firstName: this.adsData.category.firstName || '',
            first: this.adsData.category.first || '',
            secondName: this.adsData.category.secondName || '',
            thirdName: this.adsData.category.thirdName || '',
            naverPlaceUrl: this.adsData.naverPlaceUrl || ''
          },
          type: this.adsData.type || 'S',
        },
        showNaverMapInfo: false,
        naverMapInfoStep: 0,
        naverMapInfo: {
          name: '',
          abstraction: '',
          address: '',
          contacts: '',
          introduction: ''
        },
        showGuide: false,
        premiumPackages: [],
      }
    },
    watch: {
      'form.naverPlaceUrl': function (value) {
        if (value.length === 0) {
          this.form.naverPlaceUrl = null;
        }
        if (value.indexOf('http') > 0) {
          const regex = /http[s]?:\/\/[^\s]+/g;
          const links = value.match(regex);
          if (links) {
            this.form.naverPlaceUrl = links[0];
          }
        }
      }
    },
    methods: {
      changeScope(value) {
        this.form.category.first = this.option.category[value].id;
        this.form.category.secondName = '';
        this.form.category.second = '';
        this.form.category.thirdName = '';
        this.form.category.third = '';
        this.checkDone();
      },
      changeField(value) {
        this.form.category.second = this.option.category[this.form.category.firstName].sub[value].id;
        this.form.category.thirdName = '';
        this.form.category.third = '';
        this.checkDone();
      },
      changeDetail(value) {
        this.form.category.third = this.option.category[this.form.category.firstName].sub[this.form.category.secondName].sub[value].id;
        this.checkDone();
      },
      checkDone() {
        console.log('checkDone');
        if (this.form.category.first && this.form.category.second && this.form.category.third) {
          // this.$emit('updateEvent', this._.defaults(this.form, this.adsData));
        }
        console.log(this.form)
        this.form.step = 1;
        this.$emit('updateEvent', this.form);
        console.log(this.form)
      },
      async getNaverMapInfo() {
        // this.naverMapInfoStep = 1;
        let isValid = (this.form.naverPlaceUrl.length > 0 && this.form.naverPlaceUrl.indexOf('naver') > -1
            && this.form.naverPlaceUrl.indexOf('blog') === -1 && this.form.naverPlaceUrl.indexOf('search') === -1);
        if (isValid) {
          EventBus.$emit("save:ads");
          let timerId = setInterval(async () => {
            if (this.adsData.id > 0) {
              clearInterval(timerId);
              let naverMapInfo = await this.axios.get('/utils/naverme?naverUrl=' + this.form.naverPlaceUrl + "&id=" + this.adsData.id);
              if (naverMapInfo.status === 200 && naverMapInfo.data.storeId) {
                this.naverMapInfo = naverMapInfo.data;
                this.naverMapInfo.step = 2;
                this.naverMapInfo.naverPlaceUrl = this.form.naverPlaceUrl;
                console.log(this.naverMapInfo);
                this.$emit('updateEvent', this.naverMapInfo);
                this.naverMapInfoStep = 2;
                EventBus.$emit("update:ads");
              }
              else {
                this.form.naverPlaceUrl = '';
                alert('불러오기 실패');
              }
            }
          }, 2000);
          setTimeout(() => {
            clearInterval(timerId);
            if (this.adsData.id === undefined) alert('불러오기 실패')
          }, 10000);
        }
        else {
          alert('유효한 URL이 아닙니다.');
        }
      },
      getNaverMapClose() {
        this.naverMapInfoStep = 0;
      }
    },
    async created() {
      let premiumPackages = await this.axios.get(
          `company/user/premium?companyId=${this.user.id}&orderBy=id&direction=desc`
      );
      this.premiumPackages = premiumPackages.data.packages;
      // this.checkDone();
    }
  }
</script>
<style scoped lang="scss">
  .strikethrough {
    text-decoration: line-through;
  }

  .radio_group {
    margin-top: 0px;
  }

  .radio {
    border: 1px solid;
    border-radius: 8px;
    max-width: 170px;
    height: 40px;
    margin-top: 0px;
    width: 170px;
  }

  .radio.v-item--active {
    border-color: var(--rouge-color);
  }

  .no-hover-effect:hover, .no-hover-effect:active {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .no-hover-effect::before {
    background-color: transparent !important;
  }
</style>