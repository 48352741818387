var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-stepper',{attrs:{"value":_vm.adsData.step,"alt-labels":""},model:{value:(_vm.adsData.step),callback:function ($$v) {_vm.$set(_vm.adsData, "step", $$v)},expression:"adsData.step"}},[_c('v-stepper-header',[_vm._l((_vm.pages),function(page,index){return [_c('v-stepper-step',{key:`${index}-step`,attrs:{"complete":_vm.adsData.step > page.step,"step":page.step}},[(_vm.adsData.step === page.step)?_c('span',{staticClass:"rouge--text"},[_vm._v(_vm._s(page.title))]):_vm._e()]),(index !== _vm.pages.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2),_c('v-stepper-items',_vm._l((_vm.pages),function(page,index){return _c('v-stepper-content',{key:`${index}-content`,staticClass:"ma-0 pa-0 grey lighten-5",attrs:{"step":page.step}},[_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('keep-alive',[_c(page.component,_vm._b({directives:[{name:"dynamic-events",rawName:"v-dynamic-events",value:(_vm.customEvents),expression:"customEvents"}],tag:"component"},'component',_vm.curProps,false))],1),_c('div',{staticClass:"shades white pa-6 pb-12"},[(index !== 0)?_c('v-btn',{staticClass:"ma-0 ml-4",staticStyle:{"width":"120px"},attrs:{"color":"rouge","text":"","outlined":""},on:{"click":function($event){_vm.adsData.step--}}},[_vm._v("뒤로가기")]):_vm._e(),(index !== _vm.pages.length - 1)?_c('v-btn',{staticClass:"rouge white-two--text ma-0 ml-4",style:('opacity:' + (page.done ? '1.0' : '0.2') + '; width:120px'),on:{"click":function($event){return _vm.checkRequiredInfo(page.step)}}},[_vm._v(" 다음 ")]):_c('v-btn',{staticClass:"ma-0 mr-4",staticStyle:{"float":"right","width":"120px"},attrs:{"color":"primary"},on:{"click":function($event){_vm.adsData.category.firstName === '매장'
                      ? (_vm.showComplete = true)
                      : _vm.registerAds()}}},[_vm._v(" 광고등록 ")])],1)],1)],1)}),1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"content-class":"white-two","persistent":"","max-width":"500px"},model:{value:(_vm.showStop),callback:function ($$v) {_vm.showStop=$$v},expression:"showStop"}},[_c('v-card',{staticClass:"px-6 pt-2 pb-4"},[_c('v-card-title',{staticClass:"pb-1"},[_c('div',[_c('v-icon',{attrs:{"color":"default"},on:{"click":function($event){_vm.showStop = false}}},[_vm._v("close")]),_c('h2',{staticClass:"mt-4 font-weight-bold headline"},[_vm._v(" 광고 등록에 어려움이 있으신가요? ")])],1)]),_c('v-card-text',[_c('div',{staticClass:"body-1"},[_vm._v(" 광고 등록에 관해 궁금한 점이 있으시다면, "),_c('span',{staticClass:"rouge--text"},[_vm._v("오른쪽 하단 채팅버튼")]),_vm._v("을"),_c('span',{staticClass:"desktop"}),_vm._v(" 클릭하여 도움을 받으실 수 있습니다."),_c('span',{staticClass:"desktop"}),_vm._v(" 현재 작성중인 광고를 임시 저장하고, 언제든지 현재 페이지로"),_c('span',{staticClass:"desktop"}),_vm._v(" 돌아오실 수 있습니다. ")])]),_c('v-card-actions',[_c('v-btn',{staticClass:"rouge white-two--text",attrs:{"text":""},nativeOn:{"click":function($event){_vm.showStop = false}}},[_vm._v("뒤로")]),_c('v-btn',{staticClass:"ma-0 ml-4",attrs:{"color":"rouge","text":"","outlined":""},on:{"click":function($event){_vm.showStop = false;
              _vm.save();
              _vm.$router.push('/company/report');}}},[_vm._v("임시저장 후 종료")])],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"content-class":"white-two","persistent":"","max-width":"500px"},model:{value:(_vm.showComplete),callback:function ($$v) {_vm.showComplete=$$v},expression:"showComplete"}},[_c('v-card',{staticClass:"px-8 pt-2 pb-2"},[_c('v-card-title',{staticClass:"pb-1 pl-"},[_c('div',[_c('h2',{staticClass:"mt-4 font-weight-bold headline"},[_vm._v(" 카톡채널 '슈퍼멤버스'로"),_c('br'),_vm._v("꼭 업체명을 알려주세요. ")])])]),_c('v-card-text',[_c('span',{staticClass:"body-1"},[_vm._v(" 슈퍼멤버스는 광고진행 알림을 카톡으로 드리고 있습니다."),_c('br'),_vm._v(" 하단 카톡채널 버튼을 누르신 후 업체명을 보내주셔야 광고진행이 가능합니다."),_c('br'),_c('span',{staticClass:"french-blue--text"},[_vm._v("(하단 버튼 클릭 혹은 카카오톡 > 검색 슈퍼멤버스'채널)"),_c('br')]),_c('br')])]),_c('v-card-actions',{staticClass:"px-0 pt-2 pb-6"},[_c('v-btn',{staticClass:"ma-0 ml-4",attrs:{"color":"rouge white-two--text","text":""},on:{"click":function($event){_vm.showComplete = false;
              _vm.registerAds();}}},[_vm._v("완료")])],1)],1)],1),_c('v-dialog',{attrs:{"content-class":"white-two","persistent":"","max-width":"500px"},model:{value:(_vm.showRequiredInfoDlg),callback:function ($$v) {_vm.showRequiredInfoDlg=$$v},expression:"showRequiredInfoDlg"}},[_c('v-card',{staticClass:"px-8 pt-2 pb-2"},[_c('v-card-title',[_c('div',[_c('v-icon',{attrs:{"color":"default"},on:{"click":function($event){_vm.showRequiredInfoDlg = false}}},[_vm._v("close")]),_c('br'),_c('h2',{staticClass:"mt-2 font-weight-bold headline"},[_vm._v(" 누락된 필수 정보가 있습니다. 계속 진행하시겠습니까? ")])],1)]),_c('v-card-text',[_c('span',{staticClass:"body-1"},[_vm._v(" 필수 정보를 모두 입력해주셔야 광고를 개시할 수 있습니다."),_c('br'),_vm._v(" 미입력된 정보는 광고 등록 완료 후 광고 관리 페이지에서도 입력하실 수 있습니다. ")])]),_c('v-card-actions',{staticClass:"px-0 pt-2 pb-6"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"rouge","outlined":"","flat":""},on:{"click":function($event){_vm.showRequiredInfoDlg = false}}},[_vm._v("취소")]),_c('v-btn',{staticClass:"ma-0 ml-4",attrs:{"color":"rouge white-two--text"},on:{"click":function($event){_vm.showRequiredInfoDlg = false;
              _vm.nextStep(_vm.adsData.step);}}},[_vm._v("넘어가기")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }